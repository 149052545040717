import React, { useState } from "react";
import {
  Grid,
  Box,
  Typography,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  makeStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { CloudUpload, Delete, Visibility, GetApp } from "@material-ui/icons";
import * as XLSX from "xlsx";

const useStyles = makeStyles((theme) => ({
  containerView: {
    padding: theme.spacing(2),
  },
  uploadButton: {
    margin: theme.spacing(2, 0),
  },
  table: {
    minWidth: 650,
  },
  activeRow: {
    backgroundColor: theme.palette.action.selected,
  },
  previewTable: {
    minWidth: 650,
    marginTop: theme.spacing(2),
  },
}));

const LoadingBlockedClients = () => {
  const classes = useStyles();
  const [files, setFiles] = useState([
    {
      name: "Clientes bloqueados semana 1 febrero 2023.xlsx",
      date: "14-02-2024",
      status: "Activo",
      url: "/path/to/file1",
      content: null,
    },
    {
      name: "Clientes bloqueados semana 4 enero 2023.xlsx",
      date: "14-02-2024",
      status: "Inactivo",
      url: "/path/to/file2",
      content: null,
    },
  ]);
  const [previewContent, setPreviewContent] = useState(null);
  const [open, setOpen] = useState(false);

  const handleUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
        const content = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });
        const newFile = {
          name: file.name,
          date: new Date().toLocaleDateString(),
          status: "Activo",
          url: URL.createObjectURL(file),
          content,
        };
        setFiles((prevFiles) => [
          newFile,
          ...prevFiles.map((file) => ({ ...file, status: "Inactivo" })),
        ]);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handleDelete = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handlePreview = (content) => {
    setPreviewContent(content);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setPreviewContent(null);
  };

  return (
    <div className={classes.containerView}>
      <Typography variant="h5">
        Cargue de clientes bloqueados por cartera
      </Typography>
      <input
        accept=".xlsx"
        style={{ display: "none" }}
        id="file-upload"
        type="file"
        onChange={handleUpload}
      />
      <label htmlFor="file-upload">
        <Button
          variant="contained"
          color="primary"
          component="span"
          startIcon={<CloudUpload />}
          className={classes.uploadButton}
        >
          Cargar archivo
        </Button>
      </label>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Nombre archivo</TableCell>
              <TableCell>Fecha de carga</TableCell>
              <TableCell>Estado</TableCell>
              <TableCell>Detalle</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {files.map((file, index) => (
              <TableRow
                key={index}
                className={file.status === "Activo" ? classes.activeRow : ""}
              >
                <TableCell>{file.name}</TableCell>
                <TableCell>{file.date}</TableCell>
                <TableCell>{file.status}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleDelete(index)}>
                    <Delete color="secondary" />
                  </IconButton>
                  <IconButton onClick={() => handlePreview(file.content)}>
                    <Visibility color="primary" />
                  </IconButton>
                  <IconButton component="a" href={file.url} download>
                    <GetApp color="action" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Previsualización del archivo</DialogTitle>
        <DialogContent>
          {previewContent && (
            <TableContainer component={Paper}>
              <Table
                className={classes.previewTable}
                aria-label="preview table"
              >
                <TableHead>
                  <TableRow>
                    {previewContent[0].map((cell, index) => (
                      <TableCell key={index}>{cell}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {previewContent.slice(1).map((row, rowIndex) => (
                    <TableRow key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <TableCell key={cellIndex}>{cell}</TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LoadingBlockedClients;
