import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Switch,
  Paper,
  makeStyles,
} from "@material-ui/core";
import { ExpandMore, Search } from "@material-ui/icons";
import OrdersRequestController from "../../../controllers/OrdersRequest/index";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  containerView: {
    padding: theme.spacing(3),
    backgroundColor: "#f9f9f9",
    borderRadius: theme.spacing(1),
  },
  title: {
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  searchBox: {
    marginBottom: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
  },
  searchField: {
    width: "100%",
    marginRight: theme.spacing(2),
  },
  accordionSummary: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  detailContent: {
    backgroundColor: "#f5f5f5",
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
  },
  approved: {
    backgroundColor: "#e8f5e9", // Verde
  },
  rejected: {
    backgroundColor: "#ffebee", // Rojo
  },
  requestMoreInfo: {
    backgroundColor: "#fff8e1", // Amarillo
  },
  switchContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: theme.spacing(3),
  },
  switchLabel: {
    margin: theme.spacing(0, 2),
  },
  fileLink: {
    cursor: "pointer",
    color: theme.palette.primary.main,
    textDecoration: "underline",
    marginTop: theme.spacing(1),
  },
}));

const ManageUnlockRequests = (props) => {
  const {
    handleGetClientUnlockRequest,
    clientUnlockRequest,
    handleGetDocumentsById,
  } = OrdersRequestController(props);
  const classes = useStyles();

  const [isTransfer, setIsTransfer] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(
    () => {
      const fetchData = async () => {
        await handleGetClientUnlockRequest(
          props.userToken,
          null,
          isTransfer,
          null,
          true
        );
      };

      if (props.userToken) {
        fetchData();
      }
    },
    [props.userToken, isTransfer]
  );

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredRequests = clientUnlockRequest
    .filter(
      (request) =>
        request.statusName === "Desbloqueo temporal" ||
        request.statusName === "Desbloqueo permanente" ||
        request.statusName === "Rechazada" ||
        request.statusName === "Solicitar más información"
    )
    .filter((request) =>
      request.order_id.toLowerCase().includes(searchTerm.toLowerCase())
    );

  return (
    <div className={classes.containerView}>
      <Typography variant="h5" className={classes.title}>
        Historial de solicitudes de desbloqueo
      </Typography>
      <Box className={classes.switchContainer}>
        <Typography className={classes.switchLabel}>Pedidos</Typography>
        <Switch
          checked={isTransfer}
          onChange={() => setIsTransfer(!isTransfer)}
          color="primary"
        />
        <Typography className={classes.switchLabel}>Traslados</Typography>
      </Box>
      <Box className={classes.searchBox}>
        <TextField
          className={classes.searchField}
          label="Buscar"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearch}
          InputProps={{
            endAdornment: <Search />,
          }}
        />
      </Box>

      {filteredRequests.map((request, index) => {
        let accordionClass = "";

        switch (request.statusName) {
          case "Desbloqueo temporal":
          case "Desbloqueo permanente":
            accordionClass = classes.approved;
            break;
          case "Rechazada":
            accordionClass = classes.rejected;
            break;
          case "Solicitar más información":
            accordionClass = classes.requestMoreInfo;
            break;
          default:
            accordionClass = "";
        }

        return (
          <Accordion key={index} className={accordionClass}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              className={classes.accordionSummary}
            >
              <Typography>
                ID:
                {request.order_id} - Estado:
                {request.statusName} - Shipto:
                {request?.receiver
                  ? request?.receiver
                  : request?.skus[0]?.name_shipto}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.detailContent}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">
                    <strong>Account Manager: </strong>
                    {request.email}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Shipto: </strong>
                    {request?.receiver
                      ? request?.receiver
                      : request?.skus[0]?.name_shipto}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Coordinador: </strong>
                    {request?.aprobation_user}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">
                    <strong>División: </strong>
                    {request?.div}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Fecha Solicitud: </strong>
                    {request.creation_time}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Fecha de aprobación: </strong>
                    {request.aprobation_time}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">
                    <strong>Productos:</strong>
                  </Typography>
                  {request.skus.map((product, idx) => (
                    <Typography key={idx} variant="body2">
                      {product.desc} - {product.quantity}
                    </Typography>
                  ))}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">
                    <strong>Valor estimado: </strong>$
                    {request.estimatedValue.toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">
                    <strong>Observación Account Manager:</strong>{" "}
                    {request.observationUnlock}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">
                    <strong>Soportes:</strong>
                  </Typography>
                  {request.documents.map((file, idx) => (
                    <Typography
                      key={idx}
                      className={classes.fileLink}
                      onClick={() =>
                        handleGetDocumentsById(
                          file.document_id,
                          file.name || file.fileName
                        )
                      }
                    >
                      {file.name || file.fileName}
                    </Typography>
                  ))}
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

export default withRouter(
  connect((state) => ({
    userToken: state.root.user.userToken,
    userInfo: state.root.user.userInfoToken,
    data: state.root.general.dataUserAPI,
  }))(ManageUnlockRequests)
);
