// @material-ui/icons
import React from "react";
//@fortawesome
import {
  faPills,
  faUserTie,
  faUserTag,
  faUserDoctor,
  faHospital,
  faBarcode,
  faLink,
  faPeopleCarryBox,
  faFileImport,
  faUser,
  faHouseChimney,
  faBagShopping,
  faChartLine,
  faBox,
  faSignOutAlt,
  faBoxOpen,
  faRefresh,
  faBell,
  faFile,
  faIndent,
  faRotateLeft,
  faRightLeft,
  faClockRotateLeft,
  faCircleCheck,
  faRectangleList,
  faBoxesStacked,
  faShuffle,
  faBoxesPacking,
  faTruck,
  faUnlock,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";

import Account from "views/Dashboard/Dashboard";

// views account
import Profile from "views/Dashboard/Account/profile";
import Notification from "views/Dashboard/Alerts/Notifications";
import ExpiredInventory from "../views/Dashboard/Alerts/ExpiredInventory";
import Password from "views/Dashboard/Account/password";

// views members
import Manager from "views/Members/Manager/manager";
import SalesExecutives from "views/Members/SalesEcexutives.js/SalesExecutive";
import ClinicalSpecialist from "views/Members/ClinicasSpecialist/ClinicalSpecialist";
import ClinicHospitals from "views/Members/ClinicHospital";
import ClinicDepartment from "views/Members/ClinicHospitalDeparment";
import ClinicDepartmentPlace from "views/ClinicDeparmentPlace";
import PurchaseOrder from "views/PurchaseOrders";
import EditMember from "views/EditMember";

//views notification
import SettingDays from "views/Notification/settingDays";
//views scanned batch products
import Batch from "views/Dashboard/scann/batch";
import Incoming from "views/Dashboard/scann/incoming";

//views Commercial rules
import Rebates from "views/Dashboard/Rules/Rebates";
import FreeGood from "views/Dashboard/Rules/FreeGood";

import Products from "views/Dashboard/Products";

//loads

//orders dispatch
import Order from "views/Dashboard/Order";
import OrderDetail from "views/Dashboard/Order/Details";

//trueFreight
import TrueFreight from "views/Dashboard/TrueFreight";
import Blockchain from "views/Dashboard/TrueFreight/Blockchain";

//import
import WarehouseInventory from "views/Dashboard/Import/Warehouse";
import IncommingInventory from "views/Dashboard/Import/Incomming";
import ClinicInventory from "views/Dashboard/Import/Inventory";
import ProductPrice from "views/Dashboard/Import/ProductPrices";
import InternalReferences from "views/Dashboard/Import/InternalReferences";
import ParLevel from "views/Dashboard/Import/ParLevel";
import Specialist from "views/Dashboard/Import/Specialist";
import Master from "views/Dashboard/Import/Master";
import ICRP from "views/Dashboard/Import/Icrp";
import History from "views/Dashboard/Import/History";
import Ams from "views/Dashboard/Import/AMS";
import AddMember from "views/Dashboard/AddUser/index";
import AddClinicHospital from "views/Dashboard/AddClininHospital";
import Maps from "views/Dashboard/Maps";

//specialist
import InventoryProducts from "views/Specialists/InventoryProducts";
import jwtDecode from "jwt-decode";

//Products
import IncomingProducts from "views/Clinic/IncomingProducts";
import Inventory from "views/Clinic/InventoryProducts";
import TransferredProducts from "views/Specialists/TransferredProducts";

//Account manager
import OrderRequest from "views/OrderRequest";
import Approvalhistory from "views/Approvalhistory";
import BackOrderModule from "views/BackOrderModule";
import Transfersrequest from "views/TransfersRequest";
import ReturnRequest from "views/ReturnRequest";
import PendigReturnOrders from "views/ReturnRequest/PendingOrders";
import PendigTransfersOrders from "views/TransfersRequest/PendigOrders";
import Transfershistory from "views/TransfersRequest/Transfershistory";
import OrderTransferStatus from "views/TransfersRequest/OrderStatus";
import OrderReturntStatus from "views/ReturnRequest/OrderStatus";
import OrderRequestStatus from "views/OrderRequest/OrderStatus";
import UnlockRequest from "views/UnlockRequests/AccountManagerRequests";
import ExportReportsAccountManager from "views/Reports/ExportReportsAccountManager";

//reverse-logistics
import ReturnRequestHistory from "views/ReturnRequest/ReturnRequestHistory";
import ReturnRequestReports from "views/Reports/ReportsAccountManager";

import Files from "views/Files";

//group-management
import OrderAuthorization from "views/OrderAuthorization";

//Finance
import TransfersrequestAutorizationFinance from "views/TransfersrequestAuthorization/finance";
import ReturnRequestAuthorization from "views/ReturnRequestAuthorization";

//Quality
import TransfersrequestAutorizationQuality from "views/TransfersrequestAuthorization/quality";

//portfolio-coordinator
import LoadingBlockedClients from "views/UnlockRequests/admin/LoadingBlockedClients";
import ManagementBlockedClients from "views/UnlockRequests/admin/ManagementBlockedClients";
import ManageUnlockRequests from "views/UnlockRequests/admin/ManageUnlockRequests";
import UnlockRequestHistory from "views/UnlockRequests/admin/UnlockRequestHistory";

const accountRoutes = [
  {
    name: "My Account",
    icon: faUser,
    state: "openAccount",
    collapse: true,
    views: [
      {
        path: "/dashboard/profile",
        name: "Edit Profile",
        component: Profile,
      } /*,
      {
        path: "/dashboard/my-api",
        name: "My api",
        component: Api,
      },
      {
        path: "/dashboard/email-setting",
        name: "Notification setting",
        component: Notification,
      }*/,
      {
        path: "/dashboard/password-change",
        name: "Change password",
        component: Password,
      },
    ],
  },
  {
    name: "Notifications",
    icon: faBell,
    component: Notification,
    alert: true,
    path: "/dashboard/notifications",
  },
];

const accountRoutes2 = [
  {
    name: "My Account",
    icon: faUser,
    state: "openAccount",
    collapse: true,
    views: [
      {
        path: "/dashboard/profile",
        name: "Edit Profile",
        component: Profile,
      } /*,
      {
        path: "/dashboard/my-api",
        name: "My api",
        component: Api,
      },
      {
        path: "/dashboard/email-setting",
        name: "Notification setting",
        component: Notification,
      }*/,
      {
        path: "/dashboard/password-change",
        name: "Change password",
        component: Password,
      },
    ],
  },
];

export const getDashboardRoutes = (userToken) => {
  try {
    var jwt = jwtDecode(userToken);
    //var applicationId = jwt.applicationId;
    //var financePartnerId = jwt.financePartnerId;
    var cpDashboardRoutes = [];

    //TODO: se agrega esta validacion para que no aparezcan estas rutas en el account manager
    if (jwt.groupSlug !== null) {
      if (
        jwt.groupSlug !== "group-management" &&
        jwt.groupSlug !== "finance " &&
        jwt.groupSlug !== "Account-manager" &&
        jwt.groupSlug !== "quality" &&
        jwt.groupSlug !== "finance" &&
        jwt.groupSlug !== "reverse-logistics" &&
        jwt.groupSlug !== "super-user" &&
        jwt.groupSlug !== "Backorder" &&
        jwt.groupSlug !== "portfolio-coordinator"
      ) {
        cpDashboardRoutes.push(
          {
            path: "/dashboard",
            name: "Dashboard",
            exact: true,
            icon: faHouseChimney,
            component: Account,
          },
          {
            path: "/dashboard/member/add",
            name: "Add member",
            component: AddMember,
            children: true,
          }
        );
      } else {
        cpDashboardRoutes.push({
          path: "/dashboard/member/add",
          name: "Add member",
          component: AddMember,
          children: true,
        });
      }

      if (
        jwt.groupSlug !== "group-management" &&
        jwt.groupSlug !== "finance " &&
        jwt.groupSlug !== "Account-manager" &&
        jwt.groupSlug !== "quality" &&
        jwt.groupSlug !== "finance" &&
        jwt.groupSlug !== "reverse-logistics" &&
        jwt.groupSlug !== "super-user" &&
        jwt.groupSlug !== "Backorder" &&
        jwt.groupSlug !== "portfolio-coordinator"
      ) {
        cpDashboardRoutes = cpDashboardRoutes.concat(accountRoutes);
      } else {
        cpDashboardRoutes = cpDashboardRoutes.concat(accountRoutes2);
      }
    }

    if (jwt.groupSlug === "super-user") {
      cpDashboardRoutes.push(
        {
          name: "Pedidos",
          icon: faBox,
          state: "orders",
          collapse: true,
          views: [
            {
              path: "/dashboard/pedidos",
              name: "Solicitud de Pedidos",
              icon: faBox,
              component: OrderRequest,
            },
            {
              path: "/dashboard/estadodepedidos",
              name: "Estado de pedidos",
              icon: faTruck,
              component: OrderRequestStatus,
            },
            {
              path: "/dashboard/pedidosbackorder",
              name: "Pedidos BackOrder",
              icon: faBoxesPacking,
              component: BackOrderModule,
            },
          ],
        },
        {
          name: "Retornos",
          icon: faRotateLeft,
          state: "returns",
          collapse: true,
          views: [
            {
              path: "/dashboard/solicitudretorno",
              name: "Solicitud de retorno",
              icon: faRotateLeft,
              component: ReturnRequest,
            },
            {
              path: "/dashboard/retornospendientes",
              name: "Retornos pendientes",
              icon: faClockRotateLeft,
              component: PendigReturnOrders,
            },
            {
              path: "/dashboard/estadoderetornos",
              name: "Estado de retornos",
              icon: faTruck,
              component: OrderReturntStatus,
            },
          ],
        },

        {
          name: "Traslados",
          icon: faRightLeft,
          state: "transfers",
          collapse: true,
          views: [
            {
              path: "/dashboard/solicitudtraslados",
              name: "Solicitud de traslados",
              icon: faRightLeft,
              component: Transfersrequest,
            },
            {
              path: "/dashboard/trasladospendientes",
              name: "Traslados pendientes",
              icon: faShuffle,
              component: PendigTransfersOrders,
            },
            {
              path: "/dashboard/estadodetraslados",
              name: "Estado de traslados",
              icon: faTruck,
              component: OrderTransferStatus,
            },
          ],
        },
        {
          name: "Reportes",
          icon: faChartLine,
          state: "reports",
          collapse: true,
          views: [
            {
              path: "/dashboard/reports",
              name: "Estadisticas",
              icon: faChartLine,
              component: ReturnRequestReports,
            },
            {
              path: "/dashboard/exportreports",
              name: "Consulta",
              icon: faUpload,
              component: ExportReportsAccountManager,
            },
          ],
        }

        /*   {
          name: "Reportes",
          icon: faChartLine,
          //state: "returns",
          collapse: true,
          views: [
            {
              path: "/dashboard/reports",
              name: "Inventario - Beta",
              icon: faChartLine,
              component: ReturnRequestReports,
            },
          ],
        } */

        /*  {
          path: "/dashboard/autorizacionpedidos",
          name: "Autorización Pedidos",
          icon: faIndent,
          component: OrderAuthorization,
        }, */
      );
    }

    if (jwt.groupSlug === "Backorder") {
      cpDashboardRoutes.push({
        name: "Pedidos",
        icon: faBox,
        state: "orders",
        collapse: true,
        views: [
          {
            path: "/dashboard/pedidosbackorder",
            name: "Pedidos BackOrder",
            icon: faBoxesPacking,
            component: BackOrderModule,
          },
        ],
      });
    }

    if (jwt.groupSlug === "portfolio-coordinator") {
      cpDashboardRoutes.push({
        name: "Administración",
        icon: faBox,
        state: "orders",
        collapse: true,
        views: [
          /*   {
            path: "/dashboard/cargueclientesbloqueados",
            name: "Cargue de clientes Bloqueados",
            icon: faBoxesPacking,
            component: LoadingBlockedClients,
          },
          {
            path: "/dashboard/gestionclientesbloqueados",
            name: "Gestion de clientes Bloqueados",
            icon: faBoxesPacking,
            component: ManagementBlockedClients,
          }, */
          {
            path: "/dashboard/gestionsolicitudesdesbloqueo",
            name: "Solicitudes de Desbloqueo",
            icon: faBoxesPacking,
            component: ManageUnlockRequests,
          },
          {
            path: "/dashboard/historialsolicitudesdesbloqueo",
            name: "Historial solicitudes de Desbloqueo",
            icon: faBoxesPacking,
            component: UnlockRequestHistory,
          },
        ],
      });
    }

    if (jwt.groupSlug === "Account-manager") {
      cpDashboardRoutes.push(
        {
          name: "Pedidos",
          icon: faBox,
          state: "orders",
          collapse: true,
          views: [
            {
              path: "/dashboard/pedidos",
              name: "Solicitud de Pedidos",
              icon: faBox,
              component: OrderRequest,
            },
            {
              path: "/dashboard/estadodepedidos",
              name: "Estado de pedidos",
              icon: faTruck,
              component: OrderRequestStatus,
            },
            {
              path: "/dashboard/pedidosbackorder",
              name: "Pedidos BackOrder",
              icon: faBoxesPacking,
              component: BackOrderModule,
            },
          ],
        },
        {
          name: "Retornos",
          icon: faRotateLeft,
          state: "returns",
          collapse: true,
          views: [
            {
              path: "/dashboard/solicitudretorno",
              name: "Solicitud de retorno",
              icon: faRotateLeft,
              component: ReturnRequest,
            },
            {
              path: "/dashboard/retornospendientes",
              name: "Retornos pendientes",
              icon: faClockRotateLeft,
              component: PendigReturnOrders,
            },
            {
              path: "/dashboard/estadoderetornos",
              name: "Estado de retornos",
              icon: faTruck,
              component: OrderReturntStatus,
            },
          ],
        },

        {
          name: "Traslados",
          icon: faRightLeft,
          state: "transfers",
          collapse: true,
          views: [
            {
              path: "/dashboard/solicitudtraslados",
              name: "Solicitud de traslados",
              icon: faRightLeft,
              component: Transfersrequest,
            },
            {
              path: "/dashboard/trasladospendientes",
              name: "Traslados pendientes",
              icon: faShuffle,
              component: PendigTransfersOrders,
            },
            {
              path: "/dashboard/estadodetraslados",
              name: "Estado de traslados",
              icon: faTruck,
              component: OrderTransferStatus,
            },
          ],
        },
        {
          path: "/dashboard/gestionsolicitudesdesbloqueo",
          name: "Solicitudes de desbloqueo",
          icon: faUnlock,
          component: UnlockRequest,
        },

        {
          name: "Reportes",
          icon: faChartLine,
          state: "reports",
          collapse: true,
          views: [
            {
              path: "/dashboard/reports",
              name: "Estadisticas",
              icon: faChartLine,
              component: ReturnRequestReports,
            },
            {
              path: "/dashboard/exportreports",
              name: "Consulta",
              icon: faUpload,
              component: ExportReportsAccountManager,
            },
          ],
        }

        /*  {
          path: "/dashboard/autorizacionpedidos",
          name: "Autorización Pedidos",
          icon: faIndent,
          component: OrderAuthorization,
        }, */
      );
    }

    /*     {
          path: "/dashboard/historialderetornos",
          name: "Historial de retornos",
          icon: faRotateLeft,
          component: ReturnRequestHistory,
        },
        {
          path: "/dashboard/historialdetraslados",
          name: "Historial de traslados",
          icon: faRectangleList,
          component: Transfershistory,
        },
        {
          path: "/dashboard/reports",
          name: "Reportes Inventario - Beta",
          icon: faChartLine,
          component: ReturnRequestReports,
        } */

    if (jwt.groupSlug === "group-management") {
      cpDashboardRoutes.push(
        {
          name: "Pedidos",
          icon: faBox,
          state: "orders",
          collapse: true,
          views: [
            {
              path: "/dashboard/pedidos",
              name: "Solicitud de Pedidos",
              icon: faBox,
              component: OrderRequest,
            },
            /*   {
              path: "/dashboard/estadodepedidos",
              name: "Estado de pedidos",
              icon: faTruck,
              component: OrderRequestStatus,
            },
        ],
      });
    }

    if (jwt.groupSlug === "reverse-logistics") {
      cpDashboardRoutes.push(
        {
          name: "Pedidos",
          icon: faBox,
          state: "orders",
          collapse: true,
          views: [
            {
              path: "/dashboard/pedidos",
              name: "Solicitud de Pedidos",
              icon: faBox,
              component: OrderRequest,
            },
            /*   {
              path: "/dashboard/estadodepedidos",
              name: "Estado de pedidos",
              icon: faTruck,
              component: OrderRequestStatus,
            }, */
          ],
        }

        /*  {
          path: "/dashboard/historialderetornos",
          name: "Historial de retornos",
          icon: faRotateLeft,
          component: ReturnRequestHistory,
        } */
      );
    }

    if (jwt.groupSlug === "finance") {
      cpDashboardRoutes.push(
        {
          name: "Pedidos",
          icon: faBox,
          state: "orders",
          collapse: true,
          views: [
            {
              path: "/dashboard/autorizacionpedidos",
              name: "Autorización Pedidos",
              icon: faBoxesPacking,
              component: OrderAuthorization,
            },
            {
              path: "/dashboard/historialdepedidos",
              name: "Historial de pedidos",
              icon: faBoxesPacking,
              component: Approvalhistory,
            },
            /*   {
              path: "/dashboard/estadodepedidos",
              name: "Estado de pedidos",
              icon: faTruck,
              component: OrderRequestStatus,
            }, */
          ],
        },
        {
          name: "Retornos",
          icon: faRotateLeft,
          state: "returns",
          collapse: true,
          views: [
            {
              path: "/dashboard/autorizacionretornos",
              name: "Autorización Retornos",
              icon: faRotateLeft,
              component: ReturnRequestAuthorization,
            },
            {
              path: "/dashboard/retornospendientes",
              name: "Retornos pendientes",
              icon: faClockRotateLeft,
              component: PendigReturnOrders,
            },
            /* {
               path: "/dashboard/estadoderetornos",
               name: "Estado de retornos",
               icon: faTruck,
               component: OrderReturntStatus,
             },  */
            {
              path: "/dashboard/historialderetornos",
              name: "Historial de retornos",
              icon: faRotateLeft,
              component: ReturnRequestHistory,
            },
          ],
        },

        {
          name: "Traslados",
          icon: faRightLeft,
          state: "transfers",
          collapse: true,
          views: [
            {
              path: "/dashboard/autorizaciontrasladosfinanzas",
              name: "Autorización de traslados",
              icon: faRightLeft,
              component: TransfersrequestAutorizationFinance,
            },
            {
              path: "/dashboard/trasladospendientes",
              name: "Traslados pendientes",
              icon: faShuffle,
              component: PendigTransfersOrders,
            },
            /*   {
              path: "/dashboard/estadodetraslados",
              name: "Estado de traslados",
              icon: faTruck,
              component: OrderTransferStatus,
            }, */

            {
              path: "/dashboard/historialdetraslados",
              name: "Historial de traslados",
              icon: faRectangleList,
              component: Transfershistory,
            },
          ],
        },
        {
          name: "Reportes",
          icon: faChartLine,
          state: "reports",
          collapse: true,
          views: [
            {
              path: "/dashboard/exportreports",
              name: "Consulta",
              icon: faUpload,
              component: ExportReportsAccountManager,
            },
          ],
        }

        /*  {
          path: "/dashboard/reports",
          name: "Reportes Inventario - Beta",
          icon: faChartLine,
          component: ReturnRequestReports,
        } */
      );
    }

    if (jwt.groupSlug === "quality") {
      cpDashboardRoutes.push(
        {
          name: "Pedidos",
          icon: faBox,
          state: "orders",
          collapse: true,
          views: [
            /*      {
              path: "/dashboard/pedidos",
              name: "Solicitud de Pedidos",
              icon: faBox,
              component: OrderRequest,
            }, */
            /*   {
              path: "/dashboard/autorizacionpedidos",
              name: "Autorización Pedidos",
              icon: faBoxesPacking,
              component: OrderAuthorization,
            }, */
            /*     {
              path: "/dashboard/historialdepedidos",
              name: "Historial de pedidos",
              icon: faBoxesPacking,
              component: Approvalhistory,
            }, */
          ],
        },
        {
          name: "Retornos",
          icon: faRotateLeft,
          state: "returns",
          collapse: true,
          views: [
            /*     {
              path: "/dashboard/solicitudretorno",
              name: "Solicitud de retorno",
              icon: faRotateLeft,
              component: ReturnRequest,
            }, */
            {
              path: "/dashboard/retornospendientes",
              name: "Retornos pendientes",
              icon: faClockRotateLeft,
              component: PendigReturnOrders,
            },
            {
              path: "/dashboard/historialderetornos",
              name: "Historial de retornos",
              icon: faRotateLeft,
              component: ReturnRequestHistory,
            },
          ],
        },
        {
          name: "Traslados",
          icon: faRightLeft,
          state: "transfers",
          collapse: true,
          views: [
            /*     {
              path: "/dashboard/solicitudtraslados",
              name: "Solicitud de traslados",
              icon: faRightLeft,
              component: Transfersrequest,
            }, */
            {
              path: "/dashboard/trasladospendientes",
              name: "Traslados pendientes",
              icon: faShuffle,
              component: PendigTransfersOrders,
            },
            {
              path: "/dashboard/autorizaciontrasladoscalidad",
              name: "Autorización de traslados",
              icon: faRightLeft,
              component: TransfersrequestAutorizationQuality,
            },
            {
              path: "/dashboard/historialdetraslados",
              name: "Historial de traslados",
              icon: faRectangleList,
              component: Transfershistory,
            },
          ],
        },
        {
          name: "Reportes",
          icon: faChartLine,
          state: "reports",
          collapse: true,
          views: [
            {
              path: "/dashboard/exportreports",
              name: "Consulta",
              icon: faUpload,
              component: ExportReportsAccountManager,
            },
          ],
        }

        /*  {
          path: "/dashboard/reports",
          name: "Reportes Inventario - Beta",
          icon: faChartLine,
          component: ReturnRequestReports,
        } */
      );
    }

    if (
      (jwt.subGroup == "history" &&
        jwt.groupSlug !== "Backorder" &&
        jwt.groupSlug !== "portfolio-coordinator") ||
      jwt.subGroup == "all"
    ) {
      cpDashboardRoutes[2].views.push({
        path: "/dashboard/historialdepedidos",
        name: "Historial de pedidos",
        icon: faBoxesPacking,
        component: Approvalhistory,
      });
      cpDashboardRoutes[3].views.push({
        path: "/dashboard/historialderetornos",
        name: "Historial de retornos",
        icon: faRotateLeft,
        component: ReturnRequestHistory,
      });
      cpDashboardRoutes[4].views.push({
        path: "/dashboard/historialdetraslados",
        name: "Historial de traslados",
        icon: faRectangleList,
        component: Transfershistory,
      });
    }
    if (jwt.groupSlug === "main-manager") {
      cpDashboardRoutes.push({
        name: "Roles",
        icon: faUserTie,
        state: "openRoles",
        collapse: true,
        views: [
          {
            path: "/dashboard/manager",
            name: "Manager",
            icon: faUserTie,
            component: Manager,
          },
          {
            path: "/dashboard/SalesExecutives",
            name: "Sales Executives",
            icon: faUserTag,
            component: SalesExecutives,
          },
          {
            path: "/dashboard/ClinicalSpecialist",
            name: "Clinical Specialist",
            icon: faUserDoctor,
            component: ClinicalSpecialist,
          },
          {
            path: "/dashboard/ClinicHospitals",
            name: "Clinics & Hospitals",
            icon: faHospital,
            component: ClinicHospitals,
          },
        ],
      });
    }

    if (jwt.groupSlug === "manager") {
      cpDashboardRoutes.push(
        {
          path: "/dashboard/ClinicHospitals",
          name: "Clinics & Hospitals",
          icon: faHospital,
          component: ClinicHospitals,
        },
        {
          path: "/dashboard/settingDays",
          name: "Setting days",
          component: SettingDays,
          children: true,
        },
        {
          path: "/dashboard/myOrders/details",
          name: "Order detail",
          component: OrderDetail,
          children: true,
        }
      );
    }

    if (jwt.groupSlug === "main-manager" || jwt.groupSlug === "manager")
      cpDashboardRoutes.push(
        /*{
      path: "/dashboard/ClinicHospitalsDepartment",
      name: "Clinics & Hospitals deparment",
      icon: faHospitalWide,
      component: GeneralClinicHospitals
    },*/
        {
          path: "/dashboard/products",
          name: "Master Products",
          icon: faPills,
          component: Products,
        },
        {
          path: "/dashboard/member/add-clinic-hospital",
          name: "Add Clinic Hospital",
          component: AddClinicHospital,
          children: true,
        },
        {
          path: "/dashboard/maps",
          name: "Google maps",
          component: Maps,
          children: true,
        },
        {
          path: "/dashboard/member/edit",
          name: "Edit member",
          component: EditMember,
          children: true,
        }
      );

    if (jwt.groupSlug === "clinic-hospital") {
      cpDashboardRoutes.push(
        {
          path: "/dashboard/clinic-departments",
          name: "User Management",
          icon: faHospital,
          component: ClinicDepartment,
        },
        {
          path: "/dashboard/clinic-department-place",
          name: "Department / place",
          icon: faHospital,
          component: ClinicDepartmentPlace,
        },
        /*{
        path: "/dashboard/purchase-orders-report",
        name: "Purchase Orders",
        icon: faBagShopping,
        component: PurchaseOrder
      },*/ {
          name: "Products Manage",
          icon: faBoxOpen,
          state: "manageProducts",
          collapse: true,
          views: [
            {
              path: "/dashboard/incoming-products",
              name: "Incoming Products",
              component: IncomingProducts,
            },
            {
              path: "/dashboard/products-inventory",
              name: "Products Inventory",
              component: Inventory,
            },
          ],
        },
        {
          path: "/dashboard/myOrders/details",
          name: "Order detail",
          component: OrderDetail,
          children: true,
        },
        {
          path: "/dashboard/member/edit",
          name: "Edit member",
          component: EditMember,
          children: true,
        }
      );
    }

    if (
      jwt.groupSlug === "main-manager" ||
      jwt.groupSlug === "manager" ||
      jwt.groupSlug === "clinic-hospital"
    ) {
      cpDashboardRoutes.push(
        {
          name: "Scanned product",
          icon: faBarcode,
          state: "openScann",
          collapse: true,
          views: [
            {
              path: "/dashboard/scannedProductsBatch",
              name: "Scanned product batch",
              component: Batch,
            },
            {
              path: "/dashboard/scannedProductsBatchIncoming",
              name: "Scanned product batch incoming",
              component: Incoming,
            },
          ],
        },
        /*{
        name: "TrueFreight",
        icon: faLink,
        state: "openLink",
        collapse: true,
        views: [        
          {
            path: "/dashboard/trueFreight",
            name: "TrueFreight",
            component: TrueFreight,
          },
          {
            path: "/dashboard/blockchain",
            name: "Blockchain",
            component: Blockchain,
          }
        ]
      },*/ {
          name: "Orders & Dispatch",
          icon: faPeopleCarryBox,
          state: "openOrder",
          collapse: true,
          views: [
            {
              path: "/dashboard/myOrders",
              name: "My order(s)",
              component: Order,
            },
          ],
        }
      );
    }
    if (jwt.groupSlug === "main-manager") {
      cpDashboardRoutes.push({
        name: "Import",
        icon: faFileImport,
        state: "openImport",
        collapse: true,
        views: [
          {
            path: "/dashboard/importWarehouseInventory",
            name: "Warehouse Inventory",
            component: WarehouseInventory,
          },
          {
            path: "/dashboard/importClinicIncomingInventory",
            name: "Clinic Incoming Inventory",
            component: IncommingInventory,
          },
          {
            path: "/dashboard/importClinicInventory",
            name: "Clinic Inventory",
            component: ClinicInventory,
          },
          {
            path: "/dashboard/import-clinic-product-price",
            name: "Clinic Product Prices",
            component: ProductPrice,
          },
          {
            path: "/dashboard//import-clinic-product-internal-id",
            name: "Clinic Product Internal References",
            component: InternalReferences,
          },
          {
            path: "/dashboard/import-clinic-par-level",
            name: "Clinic par level",
            component: ParLevel,
          },
          {
            path: "/dashboard/import-specialist-inventory",
            name: "Clinical Specialist Inventory",
            component: Specialist,
          },
          {
            path: "/dashboard/import-product-master",
            name: "Product Master",
            component: Master,
          },
          {
            path: "/dashboard/import-product-icrp",
            name: "Product ICRP",
            component: ICRP,
          },
          {
            path: "/dashboard/import-clinic-product-historical",
            name: "Product History",
            component: History,
          },
          {
            path: "/dashboard/import-ams-inventory",
            name: "AMS inventory",
            component: Ams,
          },
        ],
      });
    }
    if (jwt.groupSlug === "clinical-specialist") {
      cpDashboardRoutes.push(
        {
          path: "/dashboard/clinical-specialist-products",
          name: "My inventory Products",
          icon: faPills,
          component: InventoryProducts,
        },
        {
          path: "/dashboard/transferred-products",
          name: "Transferred Products",
          icon: faBox,
          component: TransferredProducts,
        }
      );
    }

    if (jwt.groupSlug === "sales-executive") {
      cpDashboardRoutes.push({
        path: "/dashboard/products-inventory",
        name: "Products Inventory",
        icon: faPills,
        component: Inventory,
      });
    }

    //TODO: se agrega esta validacion para que no aparezcan estas rutas en el account manager

    if (
      jwt.groupSlug !== "group-management" &&
      jwt.groupSlug !== "finance " &&
      jwt.groupSlug !== "Account-manager" &&
      jwt.groupSlug !== "quality" &&
      jwt.groupSlug !== "finance" &&
      jwt.groupSlug !== "reverse-logistics" &&
      jwt.groupSlug !== "super-user" &&
      jwt.groupSlug !== "Backorder" &&
      jwt.groupSlug !== "portfolio-coordinator"
    ) {
      cpDashboardRoutes.push(
        {
          path: "/dashboard/import-files",
          name: "import files",
          icon: faFile,
          component: Files,
        },
        {
          path: "",
          name: "Refresh Data",
          icon: faRefresh,
          component: null,
        },
        {
          path: "",
          name: "Logout",
          icon: faSignOutAlt,
          component: null,
        }
      );
    }

    return cpDashboardRoutes;
  } catch (err) {
    //return userDashboardRoutes;
    return null;
  }
};

//export default dashboardRoutes;
