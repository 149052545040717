import React, { useState } from "react";
import {
  Grid,
  Box,
  Typography,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  makeStyles,
} from "@material-ui/core";
import { Lock, LockOpen } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  containerView: {
    padding: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
  lockIcon: {
    cursor: "pointer",
  },
}));

const ManagementBlockedClients = () => {
  const classes = useStyles();
  const [clients, setClients] = useState([
    { name: "Imbanaco", date: "14-02-2024", status: "Bloqueado" },
    { name: "Coisabanits", date: "14-02-2024", status: "Activo" },
  ]);
  const [open, setOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [observation, setObservation] = useState("");

  const handleLockClick = (client) => {
    setSelectedClient(client);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setObservation("");
    setSelectedClient(null);
  };

  const handleSave = () => {
    if (selectedClient) {
      setClients((prevClients) =>
        prevClients.map(
          (client) =>
            client.name === selectedClient.name
              ? {
                  ...client,
                  status:
                    client.status === "Bloqueado" ? "Activo" : "Bloqueado",
                }
              : client
        )
      );
      // Aquí puedes agregar la lógica para enviar la observación al backend más adelante
      // Ejemplo:
      // await axios.post('/api/update-client-status', { client: selectedClient, observation });
    }
    handleClose();
  };

  return (
    <div className={classes.containerView}>
      <Typography variant="h5">
        Gestión de clientes bloqueados por cartera
      </Typography>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Nombre cliente</TableCell>
              <TableCell>Fecha de cargue</TableCell>
              <TableCell>Estado</TableCell>
              <TableCell>Detalle</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clients.map((client, index) => (
              <TableRow key={index}>
                <TableCell>{client.name}</TableCell>
                <TableCell>{client.date}</TableCell>
                <TableCell>{client.status}</TableCell>
                <TableCell>
                  <IconButton
                    className={classes.lockIcon}
                    onClick={() => handleLockClick(client)}
                  >
                    {client.status === "Bloqueado" ? (
                      <Lock color="secondary" />
                    ) : (
                      <LockOpen color="primary" />
                    )}
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          Desbloqueo de cliente - {selectedClient?.name}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="observation"
            label="Observación área de cartera"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={observation}
            onChange={(e) => setObservation(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default">
            Volver
          </Button>
          <Button onClick={handleSave} color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ManagementBlockedClients;
